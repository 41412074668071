<template>
  <b-card>
    <!-- media -->
    <!-- <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="optionsLocal.avatar"
            height="80"
          />
        </b-link> -->
    <!--/ avatar -->
    <!-- </b-media-aside> -->

    <!-- <b-media-body class="mt-75 ml-75"> -->
    <!-- upload button -->
    <!-- <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="inputImageRenderer"
        /> -->
    <!--/ upload button -->

    <!-- reset -->
    <!-- <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          size="sm"
          class="mb-75 mr-75"
        >
          Reset
        </b-button> -->
    <!--/ reset -->
    <!-- <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
      </b-media-body>
    </b-media> -->
    <!--/ media -->

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <!-- <b-col sm="6">
          <b-form-group
            label="Username"
            label-for="account-username"
          >
            <b-form-input
              v-model="optionsLocal.username"
              placeholder="Username"
              name="username"
            />
          </b-form-group>
        </b-col> -->
        <b-col sm="6">
          <b-form-group label="Name" label-for="account-name">
            <b-form-input v-model="data.name" name="name" placeholder="Name" />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group label="E-mail" label-for="account-e-mail">
            <b-form-input
              v-model="data.email"
              name="email"
              placeholder="Email"
              disabled
            />
          </b-form-group>
        </b-col>
        <!-- <b-col sm="6">
          <b-form-group
            label="Company"
            label-for="account-company"
          >
            <b-form-input
              v-model="optionsLocal.company"
              name="company"
              placeholder="Company name"
            />
          </b-form-group>
        </b-col> -->

        <!-- alert -->
        <!-- <b-col
          cols="12"
          class="mt-75"
        >
          <b-alert
            show
            variant="warning"
            class="mb-50"
          >
            <h4 class="alert-heading">
              Your email is not confirmed. Please check your inbox.
            </h4>
            <div class="alert-body">
              <b-link class="alert-link">
                Resend confirmation
              </b-link>
            </div>
          </b-alert>
        </b-col> -->
        <!--/ alert -->

        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="isLoading"
            @click.prevent="handleOk"
          >
            <div v-if="isLoading" class="d-flex align-center">
              <b-spinner small type="grow" class="mr-1" />
              Loading...
            </div>
            <span v-else>
              Save changes
            </span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BSpinner
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useAccountSetting from './useAccountSetting'
// import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, watch } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BForm,
    // BImg,
    // BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    // BAlert,
    BCard,
    // BCardText,
    // BMedia,
    // BMediaAside,
    // BMediaBody,
    // BLink,
    BSpinner
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
      // profileFile: null,
    }
  },
  // mounted() {
  //   this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
  // },
  methods: {
    resetForm() {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
  },
  setup(props) {
    const { editAdminProfile, isLoading } = useAccountSetting()
    const initialState = {
      name: '',
    }

    const data = ref(JSON.parse(JSON.stringify(initialState)))
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(initialState))
    }

    watch(
      () => props.generalData,
      val => {
        if (val) {
          data.value.name = props.generalData.name
          data.value.email = props.generalData.email
        }

        if (!val) {
          resetData()
        }
      },
      { deep: true },
    )

    const updateAdminProfile = () => {
      editAdminProfile({
        data: data.value,
      })
    }

    const handleOk = () => {
      if (data.value.name !== props.generalData.name) {
        updateAdminProfile()
      }
    }

    // const refInputEl = ref(null)
    // const previewEl = ref(null)
    // const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)
    // return {
    //   refInputEl,
    //   previewEl,
    //   inputImageRenderer,
    // }
    return { data, handleOk, isLoading }
  },
}
</script>
