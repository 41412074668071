import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

function useAccountSetting() {
  // Use toast
  const toast = useToast()
  const isLoading = ref(false)
  const profileData = ref({})

  const fetchAdminProfile = () => {
    store
      .dispatch('account-setting/fetchAdminProfile')
      .then(response => {
        const { data } = response.data
        profileData.value = data
      })
      .catch(() => {})
  }

  const editAdminProfile = ({ data }) => {
    isLoading.value = true
    store
      .dispatch('account-setting/editAdminProfile', { data })
      .then(() => {
        isLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success editing profile',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        isLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error editing profile',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const changePassword = ({ data }) => {
    isLoading.value = true
    store
      .dispatch('account-setting/changePassword', { data })
      .then(() => {
        isLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success changing password',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
      .catch(() => {
        isLoading.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error changing password',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    profileData,

    fetchAdminProfile,
    editAdminProfile,
    changePassword,

    isLoading,
  }
}

export default useAccountSetting
