import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAdminProfile(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('admin/profile')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editAdminProfile(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/profile/update`, {
            adminname: data.name,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    changePassword(ctx, { data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/profile/change/password`, {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword,
            confirmPassword: data.confirmPassord,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
